<template>
  <div class="topic">
    <slot name="topicTitle"></slot>
    <slot name="topicSqu"></slot>
  </div>
</template>

<script>
export default {
  data () {
    return {


    }
  }

}
</script>

<style>
.topic {
  width: 100%;
  height: 280px;
  background-image: url(../../Newimg/changtu.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
}
.topic .first {
  height: 50%;
  line-height: 250px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #ffffff;
  letter-spacing: 6px;
  font-size: 60px;
}
.topic .last {
  margin: 0 auto;
  width: 20%;
  padding: 8px;
  background: #ffffff;
  border-radius: 25px;
  color: #3100cb;
  letter-spacing: 2px;
  margin-top: 40px;
}
</style>