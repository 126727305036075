
import request from '@/utils/request'
import qs from 'qs'

// 政策列表
export function policy (title,page,limit) {
  let data = {
   title,
   page,
   limit
  }
  data = qs.stringify(data)
  return request({
    url: '/policy/listPolicy',
    method: 'post',
    data
  })
}
// 政策详情
export function getHotPolicy () {
  return request({
    url: '/policy/getHotPolicy',
    method: 'get',
  })
}
// 政策详情
export function policyDetails (id) {
  return request({
    url: '/policy/detailPolicy?id=' + id,
    method: 'get',
  })
}
// 上一篇政策
export function LastpolicyDetails (id) {
  let data = {
   id
  }
  data = qs.stringify(data)
  return request({
    url: '/home/LastpolicyDetails',
    method: 'post',
    data
  })
}
// 下一篇政策
export function NextpolicyDetails (id) {
  let data = {
   id
  }
  data = qs.stringify(data)
  return request({
    url: '/home/NextpolicyDetails',
    method: 'post',
    data
  })
}

// 学术类型
export function academictype () {
  let data = {
   
  }
  data = qs.stringify(data)
  return request({
    url: '/home/Academic',
    method: 'post',
    data
  })
}
// 期刊收录
export function Include () {
  let data = {
   
  }
  data = qs.stringify(data)
  return request({
    url: '/home/Include',
    method: 'post',
    data
  })
}
// 期刊详情
export function JournalDetail (qk_id,a_id) {
  let data = {
    qk_id,a_id
  }
  data = qs.stringify(data)
  return request({
    url: '/home/JournalDetails',
    method: 'post',
    data
  })
}
// 评论条数
export function Comment (qk_id) {
  let data = {
    qk_id
  }
  data = qs.stringify(data)
  return request({
    url: '/home/Comments',
    method: 'post',
    data
  })
}
// 期刊评论
export function CommentsList (qk_id,aid) {
  let data = {
    qk_id,aid
  }
  data = qs.stringify(data)
  return request({
    url: '/home/Comment',
    method: 'post',
    data
  })
}
//查看回复评论

export function CommentReplys (comment_id,aid) {
  let data = {
    comment_id,aid
  }
  data = qs.stringify(data)
  return request({
    url: '/home/CommentReply',
    method: 'post',
    data
  })
}

// 期刊发布评论
export function PostComment (qikan_id,author_id,comment) {
  let data = {
    qikan_id,author_id,comment
  }
  data = qs.stringify(data)
  return request({
    url: '/home/PostComments',
    method: 'post',
    data
  })
}

// 回复评论
export function ReplyComment (comment_id,author_id,to_id,replycontent) {
  let data = {
    comment_id,author_id,to_id,replycontent
  }
  data = qs.stringify(data)
  return request({
    url: 'home/ReplyComments',
    method: 'post',
    data
  })
}
// 点赞// 取消点赞
export function GiveTheThumb (id,tp,aid) {
  let data = {
    id,tp,aid
  }
  data = qs.stringify(data)
  return request({
    url: '/home/GiveTheThumbs',
    method: 'post',
    data
  })
}
// 取消点赞
export function QXThumb (id,tp) {
  let data = {
    id,tp
  }
  data = qs.stringify(data)
  return request({
    url: '/home/QXThumbs',
    method: 'post',
    data
  })
}
// 搜索期刊文献
export function searchdoc (name,page,limit,sort) {
  let data = {
    name,page,limit,sort
  }
  data = qs.stringify(data)
  return request({
    url: '/home/search',
    method: 'post',
    data
  })
}

// 首页最新期刊

export function Classification (page,limit,sort) {
  let data = {
    page,limit,sort
  }
  data = qs.stringify(data)
  return request({
    url: '/home/Classification',
    method: 'post',
    data
  })
}
// 首页推荐期刊
export function Classifications (page,limit,sort) {
  let data = {
    page,limit,sort
  }
  data = qs.stringify(data)
  return request({
    url: '/home/Classification',
    method: 'post',
    data
  })
}
// 作者好评
export function AuthorRecommend (page,limit) {
  let data = {
    page,limit
  }
  data = qs.stringify(data)
  return request({
    url: '/home/authorRecommend',
    method: 'post',
    data
  })
}
// 人气文献
export function renQiWenXian (page,limit) {
  let data = {
    page,limit
  }
  data = qs.stringify(data)
  return request({
    url: '/home/renQiWenXian',
    method: 'post',
    data
  })
}
// 友情链接
export function links (page,limit) {
  let data = {
    page,limit
  }
  data = qs.stringify(data)
  return request({
    url: '/home/links',
    method: 'post',
    data
  })
}
// 软驻
export function selectSoftPaper () {
  let data = {
    
  }
  data = qs.stringify(data)
  return request({
    url: '/home/selectSoftPaper',
    method: 'post',
    data
  })
}
// 首页轮播
export function Carousel (picturePlace) {
  let data = {
    picturePlace
  }
  data = qs.stringify(data)
  return request({
    url: '/shufflingPicture/carouselByType',
    method: 'post',
    data
  })
}

//相关期刊
export function ClassificationList (page,limit,type_id) {
  let data = {
    page,limit,type_id
  }
  data = qs.stringify(data)
  return request({
    url: '/home/Classification',
    method: 'post',
    data
  })
}
// 相关文献
export function Literature (page,limit,type_id) {
  let data = {
    page,limit,type_id
  }
  data = qs.stringify(data)
  return request({
    url: '/home/Literature',
    method: 'post',
    data
  })
}
// 期刊入驻
export function applyQiKan (name,phone,qq_wx,magazine_name) {
  let data = {
    name,phone,qq_wx,magazine_name
  }
  data = qs.stringify(data)
  return request({
    url: '/home/applyQiKan',
    method: 'post',
    data
  })
}