<template>
  <div>
    <div class="toppicd">
      <img src="../../../Newimg/zhengce.jpg" alt="" />
    </div>
    <div class="bg" style="position:relative;z-index:2" v-for="(item, index) in magzines" :key="index">
      <div class="w mcont">
        <div class="magzinepolicy">
          <div class="mthem">
            <span class="mthemse">{{ item.thems }}</span>
            <div class="mthems"></div>
          </div>
          <div class="search">
            <el-input
              placeholder="请输入内容"
              v-model="keywords"
              class="input-with-select"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="mypolicy"
              ></el-button>
            </el-input>
          </div>
        </div>
      </div>
      <div class="document-body">
        <div class="document">
          <div class="weizhi">当前位置：智选期刊＞职改政策</div>
          <div
            class="policy"
            v-for="(item, index) in policy"
            :key="index"
            @click="downloadAttach(item.addressUrl)"
          >
            <div class="policy-title">
              <span class="title-name">{{ item.title }}</span>
            </div>
            <div class="title-time">{{ item.modifytime }}</div>
            <!-- <div class="title-bookname">{{ item.name }}</div> -->
            <!-- <div class="title-jieshao" v-html="$options.filters.ellipsis(item.content)"></div> -->
          </div>
          <div class="page">
            <el-pagination
              class="fy"
              small
              @size-change="handleSizeChange"
              :page-size="pagesize"
              :page-sizes="[9, 15, 20]"
              layout="total, sizes, prev, pager, next, jumper"
              @current-change="current_change"
              :total="total"
              background
            >
            </el-pagination>
          </div>
        </div>
        <div class="document-right">
          <div class="document-options">
            <div class="options-item" @click="gotougao">
              <img :src="require('@/assets/image/tougao.png')" alt="" />
            </div>
            <div class="options-item" @click="goqikan">
              <img :src="require('@/assets/image/ruzhu.png')" alt="" />
            </div>
          </div>
          <div class="document-hot">
            <div class="document-hot-title">热门政策</div>
            <div class="document-hot-list">
              <div
                class="document-hot-item"
                v-for="(item, index) in hot_list"
                :key="index"
                @click="downloadAttach(item.addressUrl)"
              >
                <div
                  class="document-hot-rank"
                  :class="
                    index == 0
                      ? 'color1'
                      : index == 1
                      ? 'color2'
                      : index == 2
                      ? 'color3'
                      : ''
                  "
                >
                  {{ index + 1 }}
                </div>
                <!-- v-html="item.wx_name" -->
                <div class="document-item-title">
                  <div
                    class="document-item-title"
                    style="padding: 0; border: none"
                  >
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="document-ad">
            <el-carousel height="150px" arrow="never">
              <el-carousel-item
                v-for="(item, index) in bannerrList"
                :key="index"
              >
                <img
                  :src="url + item.cover"
                  @click="golink(item.link)"
                  alt=""
                  class="carousel-img"
                />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>





  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { policy, getHotPolicy, Carousel } from "../../API/homepage/policy.js";
import Topic from "@/components/Topics.vue";
import { downloadFile } from "@/API/orderstatus";
import pdf from 'vue-pdf'

export default {
  components: {
    Topic,
    Swiper,
    SwiperSlide,
    pdf
  },
  data() {
    return {
      policy: [],
      total: 0, //默认数据总数
      pagesize: 9, //每页的数据条数
      currentPage: 1, //默认开始页面
      keywords: "",
      magzines: [{ thems: "职改政策" }],
      bgcolor: "#fff",
      bannerrList: [],
      url: "",
      hot_list: [],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 150) {
        return value.slice(0, 150) + "...";
      }
      return value;
    },
  },
  mounted() {
    // new Swiper('.swiper-container', {
    //   loop: true,
    //   // 如果需要分页器
    //   pagination: '.swiper-pagination',
    //   slidesPerView: 5,
    //   slidesPerGroup: 5,
    //   spaceBetween: 30,
    //   // 如果需要前进后退按钮
    //   navigation: {
    //     nextButton: '.swiper-button-next',
    //     prevButton: '.swiper-button-prev',
    //   },
    // })
    this.mypolicy();
    this.getHotPolicy();
    this.CarouseList();
    this.url = this.URL;
  },
  methods: {
    CarouseList() {
      Carousel(2).then((res) => {
        this.bannerrList = res.data.data;
      });
    },
    golink(url) {
      if (url == "") {
        return;
      }
      window.location.href = url;
    },
    getHotPolicy() {
      getHotPolicy().then((res) => {
        this.hot_list = res.data.data;
      });
    },
    handleSizeChange(val) {
      console.log(val);
      this.pagesize = val;
      this.mypolicy(this.type_id);
    },
    current_change: function (currentPage) {
      this.currentPage = currentPage;

      this.mypolicy(this.type_id);
    },
    gotougao() {
      this.$router.push("/Pending/contribute");
    },
    goqikan() {
      this.$router.push("/enter?now=2");
    },
    pingjia2(e) {
      this.xj2 = e + 1;
    },
    mypolicy() {
      policy(this.keywords, this.currentPage, this.pagesize).then((res) => {
        this.policy = res.data.data;
        this.total = res.data.total;
      });
    },
    showpolicydetail(e) {
      this.$router.push({
        path: "/policy",
        query: {
          id: e,
        },
      });
    },
//下载
    downloadAttach(path) {
     // this.dialogVisible = false;
     // let Path = this.articlelist.enclosure;
      console.log(path);
    //  alert(path);
      if(path==''|| path==null){
        this.$message.warning("政策内容未上传，请联系客服人员进行咨询！");
      }else {
       // downloadFile(path);

        // document.getElementById('div1').innerHTML = '读取中...';
        // $(document).ready(function(){
        //   $("#div1").load(path);
        // });

        this.$router.push({
          path: "/code",
          query: {
            path: path,
          },
        });

      }

    },
  },
};
</script>

<style scoped>
.start {
  width: 20px;
  cursor: pointer;
}
.magzinepolicy {
  padding-bottom: 30px;
}
.magzinepolicy .mthem {
  text-align: center;
  height: 42px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141414;
  line-height: 42px;
  margin-bottom: 20px;
  padding-top: 30px;
}
.policy-title {
  display: flex;
  justify-content: space-between;
}

.magpic {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.magpic div {
  text-align: center;
  width: 18%;
  cursor: pointer;
  margin-bottom: 10px;
}
.magpic div span {
  display: block;
  margin: 8px 0;
}
.magpic div img {
  width: 180px;
}
.mthems {
  margin: auto;
  width: 68px;
  height: 3px;
  background: #ff1918;
  border-radius: 0px;
  opacity: 0.42;
}
.mcont {
  margin-top: -60px;
  background: #fff;
  border-radius: 14px;
}
.policy {
  width: 100%;
  height: auto;
  border-radius: 4px;
  padding: 20px 0;
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
}
.title-name {
  width: auto;
  height: 25px;
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #353535;
  line-height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title-time {
  width: auto;
  height: 12px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #353535;
  margin-top: 6px;
  line-height: 17px;
}
.title-bookname {
  width: auto;
  height: 25px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 25px;
}
.title-jieshao {
  width: auto;
  height: auto;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-top: 16px;
  line-height: 24px;
  max-height: 72px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.document-body {
  display: flex;
  width: 80%;
  margin: 40px auto;
  justify-content: space-between;
}
.document-right {
  width: 24%;
}
.document-right .document-options .options-item img {
  width: 100%;
  margin-bottom: 20px;
  cursor: pointer;
}
.document-ad {
  background: #e5e5e5;
  border-radius: 16px;
  margin-top: 20px;
}
.document {
  padding-bottom: 30px;
  width: 74%;
  box-sizing: border-box;
  padding-right: 26px;
  padding-left: 100px;
  background: #fff;
  border-radius: 14px;
}
.document-hot {
  background: #fff;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 20px 16px;
  border-radius: 12px;
}
.document-hot .document-hot-title {
  color: #333;
  font-weight: bold;
  font-size: 18px;
}
.document-hot .document-hot-list {
  margin-top: 16px;
}
.document-hot .document-hot-list .document-hot-item {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  padding-top: 16px;
}
.document-hot-rank {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d2d2d2;
  color: #fff;
  margin-right: 16px;
}
.document-item-title {
  flex: 1;
  font-size: 14px;
  color: #a2a2a2;
  border-bottom: 1px solid #e5e5e5;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 22px;
  height: 44px;
  padding-bottom: 16px;
  transition: all 0.2s;
}
.document-item-title:hover {
  color: #535353;
  height: 100%;
  display: block;
}
.color1 {
  background: #fa9999;
}
.color2 {
  background: #f7b697;
}
.color3 {
  background: #a5d8a1;
}

.small {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search {
  display: flex;
  width: 70%;
  height: 40px;
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 26px;
  margin-bottom: 20px;
}
.weizhi {
  box-sizing: border-box;
  height: 80px;
  line-height: 100px;
  font-size: 14px;
  color: #939393;
}
</style>
<style>
.search .el-input-group .el-input__inner {
  border: none !important;
  height: 38px;
  border-radius: 20px;
}
.search .el-input-group .el-input-group__append {
  background: none;
  border: none;
  color: #75a1ea;
  font-size: 20px;
  font-weight: bold;
}
.page {
  height: 100px;
  display: flex;
  width: 100%;
  margin: 0 auto;
  line-height: 100px;
  margin-top: 30px;
  justify-content: center;
}
.carousel-img {
  width: 100%;
  height: 100%;
}
.toppicd {
  width: 100%;
}
.toppicd img {
  width: 100%;
}
</style>